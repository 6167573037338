import { useRef } from 'react';

import * as S from './FeaturedActivities.styles';
import { SliderDots } from '@/common/ui';
import { Container } from '@/common/ui/containers/Container';
import { useCarousel } from '@/common/app/hooks/useCarousel';
import { IActivity } from '@/entities/Activity/domain/Activity.domain';
import { IRecommendsItem } from './featuredActivitiesPreview/FeaturedActivitiesPreview.types';
import { FeaturedActivitiesSkeleton } from './FeaturedActivitiesSkeleton/FeaturedActivitiesSkeleton';
import { useAttractionsContext } from '@/common/app/contexts';
import { NewFeaturedActivitiesPreview } from './featuredActivitiesPreview/NewFeaturedActivitiesPreview';

export type FeaturedActivitiesProps = {
    activities: IActivity[] | IRecommendsItem[];
    title?: string;
    subTitle?: string;
    maxWidthForTitle?: string;
    isFromSearch?: boolean;
    item_list_name: string;
    item_list_id: string;
    skipSkeleton?: boolean;
    placeholders: {
        [key: string]: string;
    };
};

export const NewFeaturedActivities = ({
    activities,
    title,
    subTitle,
    maxWidthForTitle,
    isFromSearch,
    item_list_name,
    item_list_id,
    skipSkeleton,
    placeholders,
}: FeaturedActivitiesProps) => {
    const mainTitle = title || '';
    const scrollRef = useRef<HTMLDivElement>(null);
    const { loading } = useAttractionsContext();

    const {
        prevBtnIsActive,
        nextBtnIsActive,
        onClickHandler,
        onScrollHandler,
        DIRECTION,
        withDot,
        dotActive,
    } = useCarousel({ ref: scrollRef, countItems: activities.length ? activities.length : 4 });

    const skeletonObj = new Array(4).fill({ id: '' });
    if ((!loading.initLoading && !activities.length) || (skipSkeleton && !activities.length)) {
        return <></>;
    }

    return (
        <S.Wrapper>
            <Container>
                <S.Block data-test-id="Widget-attraction" className="container">
                    <S.Header>
                        <S.LeftPartHeader>
                            <S.Title style={{ maxWidth: maxWidthForTitle || 'none' }}>
                                {mainTitle}
                            </S.Title>
                            {subTitle && <S.SubTitle>{subTitle}</S.SubTitle>}
                        </S.LeftPartHeader>
                    </S.Header>
                    <S.CarouselWraper>
                        <S.Gallery ref={scrollRef} onScroll={onScrollHandler}>
                            <S.InnerContainer className="carousel-container">
                                {!skipSkeleton && loading.recommendsLoading ? (
                                    <>
                                        {skeletonObj.map((slide, index) => (
                                            <FeaturedActivitiesSkeleton key={slide.id || index} />
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        {activities.map((slide, index) => (
                                            <NewFeaturedActivitiesPreview
                                                {...slide}
                                                numberOfItems={activities.length}
                                                position={index}
                                                key={slide.id}
                                                listName={mainTitle}
                                                isFromSearch={isFromSearch}
                                                item_list_id={item_list_id}
                                                item_list_name={item_list_name}
                                                promotion_name={mainTitle}
                                                placeholder={placeholders?.[slide.id] || ''}
                                            />
                                        ))}
                                    </>
                                )}
                            </S.InnerContainer>
                        </S.Gallery>
                        {prevBtnIsActive && withDot && (
                            <S.CenterLeftArrow
                                onClick={() => onClickHandler(DIRECTION.prev)}
                                disabled={false}
                            />
                        )}
                        {nextBtnIsActive && withDot && (
                            <S.CenterRightArrow
                                onClick={() => onClickHandler(DIRECTION.next)}
                                disabled={false}
                            />
                        )}
                    </S.CarouselWraper>
                    {!!withDot && (
                        <S.DotsSlider>
                            {[...Array(activities.length).keys()].map((el) => {
                                return (
                                    <SliderDots isActive={el === Math.ceil(dotActive)} key={el} />
                                );
                            })}
                        </S.DotsSlider>
                    )}
                </S.Block>
            </Container>
        </S.Wrapper>
    );
};
