export const Calendar = (props: { className?: string }) => {
    return (
        <svg
            className={`Calendar ${props.className}`}
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.375 1.83333C12.0083 1.46667 11.4583 1.19167 10.8167 1.19167H10.0833V0.733333C10.0833 0.275 9.80834 0 9.44167 0C9.075 0 8.70834 0.275 8.70834 0.733333V1.19167H4.30833V0.733333C4.30833 0.275 3.94167 0 3.575 0C3.20833 0 2.93333 0.275 2.93333 0.733333V1.19167H2.29167C1.74167 1.19167 1.1 1.375 0.641667 1.83333C0.275 2.29167 0 2.84167 0 3.48333V12.1C0 12.65 0.183333 13.2 0.641667 13.6583C1.00833 14.025 1.55833 14.3 2.2 14.3H10.9083C11.4583 14.3 12.0083 14.025 12.4667 13.6583C12.8333 13.2917 13.1083 12.7417 13.1083 12.1V3.3C13.0167 2.75 12.8333 2.2 12.375 1.83333ZM11.6417 12.1C11.6417 12.2833 11.55 12.4667 11.4583 12.65C11.275 12.8333 11.0917 12.8333 10.9083 12.8333H2.10833C1.925 12.8333 1.74167 12.7417 1.55833 12.65C1.375 12.4667 1.375 12.2833 1.375 12.1V5.13333H10.5417C10.9083 5.13333 11.275 4.85833 11.275 4.4C11.275 3.94167 11 3.66667 10.5417 3.66667H1.375V3.48333C1.375 3.20833 1.46667 3.025 1.65 2.84167C1.83333 2.65833 2.10833 2.56667 2.29167 2.56667H2.93333C3.48333 2.56667 4 2.56667 4.30833 2.56667H8.8C9.10156 2.56667 9.69531 2.56667 10.0833 2.56667H10.8167C11 2.56667 11.1833 2.65833 11.3667 2.75C11.55 2.93333 11.55 3.11667 11.55 3.3V12.1H11.6417Z"
                fill="#0DD1C5"
            />
            <path
                d="M5.5 6.6H3.48333C3.20833 6.6 3.025 6.78333 3.025 7.05833V8.06667C3.025 8.34167 3.20833 8.525 3.48333 8.525H5.5C5.775 8.525 5.95833 8.34167 5.95833 8.06667V7.05833C5.95833 6.78333 5.775 6.6 5.5 6.6Z"
                fill="#0DD1C5"
            />
        </svg>
    );
};
