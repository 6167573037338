import styled, { css } from 'styled-components';
import Text from 'src/styles/Themes/Text';
import Screen from 'src/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';

export const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 26px;
    ${Screen.desktop} {
        margin-bottom: 40px;
    }
    ${Screen.desktop} {
        margin-bottom: 50px;
    }
`;

export const Title = styled.h1`
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.heading2}
    margin: 0 0 10px;
    color: ${cssVars.fg_gray1_white};

    ${Screen.desktop} {
        margin: 26px 0 10px;
    }
`;

export const Link = styled.a`
    color: ${({ theme }) => theme.colors.green};
    text-decoration: underline;
    ${css`
        ${Text.Mobile.medium.semibold}
        ${Screen.ipad} {
            ${Text.Ipad.heading4}
        }
        ${Screen.desktop} {
            ${Text.Desktop.large.semibold}
        }
    `}
    :active, :hover {
        color: var(--fg-green-second);
    }
`;

export const Description = styled.span`
    color: var(--fg-gray-main);
    width: 100%;
    ${css`
        ${Text.Mobile.medium.medium}
        ${Screen.ipad} {
            ${Text.Ipad.medium.medium}
        }
        ${Screen.desktop} {
            ${Text.Desktop.large.medium}
        }
    `}
`;

export const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-bottom: 20px;

    ${Screen.ipad} {
        grid-gap: 36px;
    }

    ${Screen.desktop} {
        grid-gap: 24px;
        grid-template-columns: 1fr 1fr;
        margin: 10px 0 20px;
    }
`;

export const AttractionDescription = styled.div`
    ${Text.Full.large.regular};
    margin: 10px 0 15px;
    color: ${cssVars.fg_gray1_white};

    ${Screen.desktop} {
        margin: 10px auto 20px;
    }
`;
