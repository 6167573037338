import { memo, useState, useRef, useCallback, useEffect } from 'react';

import { useAttractionsContext } from '@/common/app/contexts/AttractionsContext';

import * as S from './SortDropdown.styles';
import { Drawer } from '@/common/ui/drawer';
import { useSearchContext } from '@/common/app/contexts';
import { addDays, format, parseISO } from 'date-fns';
import { useMedia } from '@/common/service/hooks/useMedia';
import { useOnClickOutside } from '@/common/service/hooks';
import { SORT_OPTIONS } from '@/entities/Attractions/service/SortFunctions';
import { useInView } from 'react-intersection-observer';
import { SERVER_FORMAT_DATE } from 'utils/constants';
import { useRouter } from 'next/router';
import { replaceDateInPath } from '@/common/app/utils/replaceDateInPath';

const OPTIONS = Object.values(SORT_OPTIONS);

const ICONS = {
    [OPTIONS[3]]: <S.ArrowButton />,
    [OPTIONS[4]]: <S.ArrowButton up />,
};

export const Dropdown = () => {
    const [isActive, setIsActive] = useState(false);
    const [openCalendar, setOpenCalendar] = useState(false);
    const refCalendar = useRef<HTMLDivElement | null>(null);
    const refDropdown = useRef<HTMLDivElement | null>(null);
    //const { changeDate } = useDateContext();

    const { replace, asPath } = useRouter();
    const { settings, setSettings, totalLength, setLoading } = useAttractionsContext();
    const { selectedDateRange, saveDateRange } = useSearchContext();

    const [inViewRef, inView] = useInView({
        threshold: 1,
    });
    const [inViewCalendarRef, inViewCalendar] = useInView({
        threshold: 1,
    });

    useOnClickOutside(refCalendar, () => closeWithAnimation());
    useOnClickOutside(refDropdown, () => setIsActive(false));

    const setRefs = useCallback(
        (node: HTMLDivElement) => {
            refDropdown.current = node;
            inViewRef(node);
        },
        [inViewRef]
    );

    const setRefsCalendar = useCallback(
        (node: HTMLDivElement) => {
            refCalendar.current = node;
            inViewCalendarRef(node);
        },
        [inViewCalendarRef]
    );

    const isDesktop = useMedia('(min-width: 992px)');

    const today = new Date();
    const lastDay = addDays(today, 6);
    const dateStart =
        typeof selectedDateRange?.dateStart === 'string'
            ? parseISO(selectedDateRange.dateStart)
            : selectedDateRange?.dateStart || today;
    const dateEnd =
        typeof selectedDateRange?.dateEnd === 'string'
            ? parseISO(selectedDateRange.dateEnd)
            : selectedDateRange?.dateEnd || lastDay;

    const toggleIsActive = () => setIsActive((prev) => !prev);

    const onChoose = (label: string) => {
        if (label !== settings.sorting) {
            setLoading((prev) => ({
                ...prev,
                changeLoading: true,
                initChangeLoading: true,
            }));
            setSettings((prev) => ({
                ...prev,
                sorting: label,
            }));
        }
    };

    const handleOpen = () => {
        setOpenCalendar(true);
    };

    const handleClose = () => {
        setOpenCalendar(false);
    };

    const handleSearch = (dateStart: Date, dateEnd: Date) => {
        saveDateRange({ dateStart, dateEnd });

        replace(
            replaceDateInPath(
                asPath,
                format(dateStart, SERVER_FORMAT_DATE),
                format(dateEnd, SERVER_FORMAT_DATE)
            ),
            undefined,
            { scroll: false }
        );

        closeWithAnimation();
    };

    const closeWithAnimation = () => {
        setTimeout(() => {
            handleClose();
        }, 200);
    };

    useEffect(() => {
        if (isActive && !inView) {
            refDropdown.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        if (openCalendar && !inViewCalendar) {
            refCalendar.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [isActive, openCalendar, inView, inViewCalendar]);

    return (
        <S.Wrapper
            data-test-id="Sort_dropdown"
            isCategoryFilter={!!settings.attributes.length || !!settings.categories.length}
        >
            <S.CommonContainer>
                <S.Label>Sorting:</S.Label>
                <S.WrapperDropdown>
                    <S.Container isActive={isActive} ref={setRefs}>
                        <S.Title onClick={toggleIsActive}>
                            <S.TitleText>{settings.sorting}</S.TitleText>
                            <S.Arrow isActive={isActive} />
                        </S.Title>
                        {isActive && (
                            <S.Animation onClick={toggleIsActive}>
                                {OPTIONS.map((label, index) => (
                                    <S.Item
                                        key={index}
                                        onClick={() => {
                                            onChoose(label);
                                        }}
                                    >
                                        {label} {ICONS[label]}
                                    </S.Item>
                                ))}
                            </S.Animation>
                        )}
                    </S.Container>
                </S.WrapperDropdown>
                {(!!settings.attributes.length || !!settings.categories.length) && (
                    <S.TripsCountContainer>
                        <S.TripsCountSign>{`${totalLength} results`}</S.TripsCountSign>
                        <S.TripsCountSign
                            onClick={() => {
                                setLoading((prev) => ({
                                    ...prev,
                                    changeLoading: true,
                                    initChangeLoading: true,
                                }));
                                setSettings((prev) => ({
                                    ...prev,
                                    sorting: OPTIONS[0],
                                    attributes: [],
                                    categories: [],
                                }));
                            }}
                        >
                            Clear all filters
                        </S.TripsCountSign>
                    </S.TripsCountContainer>
                )}
            </S.CommonContainer>
            <S.CalendarContainer>
                <S.CalendarWrapper>
                    <S.CalendarData onClick={() => !openCalendar && handleOpen()}>
                        <S.CalendarIcon />
                        <S.Placeholder>{`${format(dateStart, 'MMM d')} - ${format(
                            dateEnd,
                            'MMM d'
                        )}`}</S.Placeholder>
                        {isDesktop && openCalendar ? (
                            <S.ResultsContainer ref={setRefsCalendar}>
                                <S.InputDate
                                    dateStart={dateStart}
                                    dateEnd={dateEnd}
                                    onChange={handleSearch}
                                    pastDisabled
                                    withButton
                                />
                            </S.ResultsContainer>
                        ) : (
                            <Drawer
                                open={openCalendar}
                                setOpen={() => handleClose()}
                                title={'Trip dates'}
                            >
                                <S.ResultsContainer isNotSearch={true}>
                                    <S.InputDate
                                        dateStart={dateStart}
                                        dateEnd={dateEnd}
                                        onChange={handleSearch}
                                        pastDisabled
                                        withButton
                                    />
                                </S.ResultsContainer>
                            </Drawer>
                        )}
                    </S.CalendarData>
                </S.CalendarWrapper>
            </S.CalendarContainer>
        </S.Wrapper>
    );
};

export default memo(Dropdown);
