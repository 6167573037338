import styled from 'styled-components';
import { cssVars } from '@/styles/Themes/cssVars';
import Palette from '@/styles/Themes/Palette';
import Screen from 'src/styles/Themes/Screen';

export const Wrapper = styled.div`
    margin-bottom: 24px;
`;

export const Title = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: ${cssVars.fg_gray1_white};
    margin-bottom: 16px;
`;

export const Container = styled.div<{ open: boolean }>`
    max-height: 116px;
    overflow: hidden;

    ${Screen.desktop} {
        max-height: ${({ open }) => (open ? 'none' : '75px')};
    }
`;

export const innerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

export const MobileContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    ${Screen.desktop} {
        gap: 10px;
    }
`;

export const Item = styled.div<{ disable: boolean; active: boolean }>`
    border: 1px solid ${({ active }) => (active ? 'transparent' : '#c4c4c4')};
    color: ${({ active }) => (active ? '#fff' : cssVars.fg_gray2_gray3)};
    padding: 4px 10px;
    border-radius: 16px;
    font-size: 12px;
    background: ${({ active }) => (active ? Palette.gradient.dark : 'transparent')};
    opacity: ${({ disable }) => (disable ? 0.5 : 1)};
    cursor: ${({ disable }) => (disable ? 'default' : 'pointer')};

    ${Screen.desktop} {
        font-size: 14px;
        padding: 4px 12px;
        &:hover {
            background: ${({ disable }) => (disable ? 'transparent' : Palette.gradient.dark)};
            border-color: ${({ disable }) => (disable ? '#c4c4c4' : 'transparent')};
            color: ${({ disable }) => (disable ? '#c4c4c4' : '#fff')};
        }
    }
`;

export const LinkWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
`;

export const Link = styled.div`
    color: #0dd1c5;
    cursor: pointer;
`;
