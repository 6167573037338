import styled, { css, DefaultTheme } from 'styled-components';
import Text from 'src/styles/Themes/Text';
import Screen from 'src/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import { radius, borders } from '@/styles/Themes/Metrics';
import Palette from '@/styles/Themes/Palette';
import { DropdownArrow } from '@/shared/images/icons/DropdownArrow';
import { Transition } from '@/styles/Themes/KeyFrames';
import { RedCross } from '@/shared/images/icons/RedCross';
import { ArrowIcon } from '@/shared/images/icons/ArrowIcon';
import { Calendar } from '@/shared/images/icons/Calendar';
import { DayPicker } from '@/common/ui';

export const Wrapper = styled.div<{ isCategoryFilter?: boolean }>`
    position: relative;
    width: 100%;
    height: 93px;
    margin: 0 0 ${({ isCategoryFilter }) => (isCategoryFilter ? '56px' : '24px')} 0;
    z-index: 2;
    background: ${cssVars.bg_ashDarker_blueDark};
    border-radius: ${radius.small};
    padding: 20px 11px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    ${Screen.ipad} {
        height: 76px;
        padding: 28px 30px;
    }

    ${Screen.desktop} {
        margin: 0 0 24px 0;
    }
`;

export const WrapperDropdown = styled.div`
    position: relative;
    height: 50px;
    width: 258px;

    ${Screen.ipad} {
        height: 40px;
        width: 284px;
    }
`;

export const CommonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
`;

export const Animation = styled.div`
    padding: 0 15px 15px 30px;
    margin-right: 15px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        width: 0;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 0;
    }

    ::-webkit-scrollbar-thumb {
        height: 0;
        background: transparent;
        border-radius: 0;
    }

    @keyframes rolldown {
        from {
            max-height: 0px;
        }

        to {
            max-height: 274px;
        }
    }
    animation-duration: 0.4s;
    animation-name: rolldown;
    animation-timing-function: ease-in-out;
`;

export const Container = styled.div<IWithIsActive>`
    width: 100%;
    height: 50px;
    background-color: ${cssVars.bg_white_blue};
    border-radius: ${radius.large};
    border: ${borders.small} ${cssVars.fg_gray3_dark1};
    position: absolute;

    ${Screen.ipad} {
        height: ${({ isActive }) => (isActive ? 'auto' : '40px')};
    }

    ${({ isActive }) =>
        isActive &&
        css`
            border-radius: ${radius.normal};
            height: auto;
            z-index: 1;
        `}
`;

export const Label = styled.div`
    display: none;
    ${Text.Desktop.medium.medium}
    color: ${cssVars.fg_gray2_gray3};
    margin-right: 12px;

    ${Screen.ipad} {
        display: block;
    }
`;

export const Title = styled.div`
    color: ${cssVars.fg_gray1_white};
    ${Text.Ipad.medium.medium}
    cursor: pointer;
    height: 100%;
    margin: 0;
    padding: 14px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
`;

export const TitleText = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 30px;
`;

interface IWithIsActive extends DefaultTheme {
    isActive: boolean;
}

export const Arrow = styled(DropdownArrow)<IWithIsActive>`
    ${({ isActive }) => isActive && 'transform: rotate(-180deg);'}
    ${Transition.normal}
    position: absolute;
    right: 30px;
`;

export const Item = styled.div`
    color: ${cssVars.fg_gray1_white};
    ${Text.Mobile.medium.medium}
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 18px 0;
    border-top: ${borders.small} ${cssVars.bg_ash_darker};
    cursor: pointer;
    padding: 18px 16px;

    ${Screen.ipad} {
        ${Text.Ipad.normal.medium}
    }
    ${Screen.desktop} {
        ${Text.Desktop.normal.medium}
    }

    &:hover {
        border-radius: ${radius.small};
        color: ${Palette.green.normal};
        background-color: ${cssVars.bg_ash_blueDark};
        ${Transition.normal}
    }
    &:hover {
        *:last-child {
            color: ${Palette.green.normal};
        }
    }
`;

export const ArrowButton = styled(ArrowIcon)<{ up?: boolean }>`
    height: 12px;
    width: 12px;
    transform: rotate(-90deg);
    margin-left: 8px;

    ${({ up }) => up && 'transform: rotate(90deg);'}
`;

export const RedCrossIcon = styled(RedCross)``;

export const CalendarContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 668px) {
        width: 30%;
        margin-left: auto;
    }
`;

export const CalendarWrapper = styled.div`
    padding: 0px;
    height: 35px;

    ${Screen.ipad} {
        border-left: 1px solid ${Palette.gray.gray4};
        padding: 0 40px;
    }

    ${Screen.ipadMini} {
        padding: 0 20px;
    }

    @media (max-width: 668px) {
        border-left: none;
        padding: 0;
        height: auto;
    }
`;

export const CalendarData = styled.div`
    width: 100%;
    height: 100%;
    background: ${cssVars.bg_white_blueDark};
    border-radius: ${radius.small};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 12px;

    @media (min-width: 668px) {
        border-radius: ${radius.designer};
        width: 160px;
        padding: 0;
    }
`;

export const CalendarIcon = styled(Calendar)`
    margin-right: 7px;
    @media (max-width: 668px) {
        width: 20px;
        height: 20px;
        margin-right: 0;
    }
`;

export const ResultsContainer = styled.div<{ isNotSearch?: boolean }>`
    margin: 0 auto;
    width: 322px;
    height: fit-content;

    ${Screen.desktop} {
        position: absolute;
        padding: 10px 20px;
        background-color: ${cssVars.bg_white_dark3};
        box-shadow: 0px 4px 23px ${cssVars.shadow_normal};
        z-index: 1;
        border-radius: ${radius.small};
        ${({ isNotSearch }) => isNotSearch && 'left: -88px'};
        top: 62px;
        right: -70px;
        width: 368px;
    }
`;

export const InputDate = styled(DayPicker)`
    width: 100%;
    height: 100%;
    background-color: ${cssVars.bg_white_blue};
`;

export const Placeholder = styled.div`
    display: none;
    ${Text.Desktop.medium.medium}
    color: ${cssVars.fg_gray2_white};

    @media (min-width: 668px) {
        display: flex;
    }
`;

export const TripsCountContainer = styled.div`
    ${Text.Desktop.medium.medium}
    color: ${cssVars.fg_gray2_gray3};
    display: flex;

    position: absolute;
    left: 0;
    top: 80px;

    ${Screen.ipad} {
        left: -30px;
        top: 70px;
    }

    ${Screen.desktop} {
        position: static;
        margin-left: 24px;
    }

    *:last-child {
        border-left: 1px solid ${Palette.gray.gray4};
        text-decoration: underline;
        cursor: pointer;
    }
`;

export const TripsCountSign = styled.div`
    padding: 8px 14px;
`;
