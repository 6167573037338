import { useAttractionsContext } from '@/common/app/contexts/AttractionsContext';
import { filterByAttribution, IAttributes, parseAttr } from '@/common/app/constants/attributions';
import * as S from './Attributes.styles';
import { useRef, useState } from 'react';
import { Drawer } from '@/common/ui/drawer';
import { useMedia } from '@/common/service/hooks/useMedia';

export const Attributes = ({ availableAttributes }: { availableAttributes: IAttributes[] }) => {
    const { settings, setSettings, setLoading, availableAttributesIds, normalisedList } =
        useAttractionsContext();
    const [open, setOpen] = useState(false);

    const isDesktop = useMedia('(min-width: 992px)');

    const innerContainerRef = useRef<HTMLDivElement>(null);
    const IsShowMore = Boolean(
        innerContainerRef.current && innerContainerRef.current.clientHeight > (isDesktop ? 75 : 116)
    );
    const onClickHandler = (id: number) => {
        if (!availableAttributesIds.includes(id)) return;
        const newList = filterByAttribution({
            trips: normalisedList,
            attributes: [...settings.attributes, id],
        });
        const ids: number[] = [];
        newList.forEach((trip) => {
            if (trip.attributes) {
                const attrs = parseAttr(trip.attributes);
                ids.push(...attrs.filter((i) => !ids.includes(i)));
            }
        });

        setLoading((prev) => ({
            ...prev,
            changeLoading: true,
            initChangeLoading: true,
        }));
        setSettings((prev) => ({
            ...prev,
            attributes: (prev.attributes.includes(id)
                ? prev.attributes.filter((p) => p !== id)
                : [...prev.attributes, id]
            ).filter((i) => ids.includes(i)),
        }));
    };

    return (
        <S.Wrapper>
            <S.Title>Narrow your search</S.Title>
            <S.Container open={open}>
                <S.innerContainer ref={innerContainerRef}>
                    {availableAttributes.map((i) => (
                        <S.Item
                            key={i.id}
                            disable={!availableAttributesIds.includes(i.id)}
                            active={settings.attributes.includes(i.id)}
                            onClick={() => onClickHandler(i.id)}
                        >
                            {i.value}
                        </S.Item>
                    ))}
                </S.innerContainer>
            </S.Container>
            {IsShowMore && (
                <S.LinkWrapper>
                    <S.Link onClick={() => setOpen((prev) => !prev)}>
                        {open ? 'View less filters' : 'View more filters'}
                    </S.Link>
                </S.LinkWrapper>
            )}
            {!isDesktop && (
                <Drawer open={open} setOpen={() => setOpen(false)} title={'Filters'}>
                    <S.MobileContainer>
                        {availableAttributes.map((i) => (
                            <S.Item
                                key={i.id}
                                disable={!availableAttributesIds.includes(i.id)}
                                active={settings.attributes.includes(i.id)}
                                onClick={() => onClickHandler(i.id)}
                            >
                                {i.value}
                            </S.Item>
                        ))}
                    </S.MobileContainer>
                </Drawer>
            )}
        </S.Wrapper>
    );
};
