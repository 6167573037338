import styled, { css } from 'styled-components';
import Text from '@/styles/Themes/Text';
import Palette from '@/styles/Themes/Palette';
import { DropdownArrow } from '@/shared/images/icons/DropdownArrow';
import { cssVars } from '@/styles/Themes/cssVars';

export const Container = styled.button<{ withSpace?: boolean }>`
    position: relative;
    ${({ withSpace }) =>
        withSpace
            ? css`
                  bottom: 8px;
                  width: 100%;
              `
            : css`
                  width: calc(100% - 16px);
                  box-shadow: 0px 16px 8px 16px ${cssVars.bg_white_blue};
                  bottom: 42px;
              `};
    display: grid;
    align-items: center;
    justify-content: center;
    grid-auto-flow: column;
    grid-gap: 8px;
    margin: auto;
    border: none;
    height: 48px;
    color: ${Palette.white.normal};
    ${Text.Full.heading4}
    background: ${cssVars.gradient_gray_blueDark};
    border-radius: 0 0 15px 15px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
        background: ${Palette.green.normal};
    }
`;

export const Arrow = styled(DropdownArrow)`
    stroke: ${Palette.white.normal};
`;
