import { memo, ReactElement } from 'react';

import { useAttractionsContext } from '@/common/app/contexts/AttractionsContext';
import { Container } from '@/common/ui/containers/Container';
import { ActivitiesListProps } from './ActivitiesList.domain';

import * as S from './ActivitiesList.styles';
import { BottomButton } from '@/common/ui/buttons/BottomButton/BottomButton';
import { Preloader } from '@/common/ui/loaders/Preloader';
import SortDropdown from './SortDropdown/SortDropdown';
import { NewActivitiesPreview } from '@/entities/Activity/ui/ActivitiesPreview/NewActivitiesPreview';
import { Attributes } from './Attributes/Attributes';

export const ActivitiesList = ({
    hideSort,
    listNameGA3,
    listName,
    listId,
    availableAttributes,
}: ActivitiesListProps): ReactElement => {
    const { onScrollPage, state, loading, isComplete, showMoreText } = useAttractionsContext();

    return (
        <Container>
            {!hideSort && <SortDropdown />}
            {!!availableAttributes.length && (
                <Attributes availableAttributes={availableAttributes} />
            )}
            {loading.changeLoading ? (
                <Preloader minHeight="100vh" />
            ) : (
                <>
                    <S.Content data-test-id="Activities_list_content">
                        {state.showList.map((activity, index) => (
                            <NewActivitiesPreview
                                activity={activity}
                                key={activity.id}
                                position={index}
                                listName={listName}
                                listId={listId}
                                listNameGA3={listNameGA3}
                                isLastItem={Boolean(
                                    !loading.initLoading &&
                                        !loading.paginationLoading &&
                                        !isComplete
                                )}
                            />
                        ))}
                        {isComplete &&
                            state.soldOutList.map((activity, index) => (
                                <NewActivitiesPreview
                                    activity={activity}
                                    key={activity.id}
                                    position={index}
                                    listName={listName}
                                    listId={listId}
                                    listNameGA3={listNameGA3}
                                />
                            ))}
                    </S.Content>
                    {loading.paginationLoading && <Preloader />}
                    {!isComplete && !loading.paginationLoading && (
                        <BottomButton
                            title={showMoreText}
                            onClick={() => onScrollPage({})}
                            isTripsList
                        />
                    )}
                </>
            )}
        </Container>
    );
};

export default memo(ActivitiesList);
