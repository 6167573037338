import styled from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import { Tomorrow } from '@/shared/images/icons/Tomorrow';
import { cssVars } from '@/styles/Themes/cssVars';
import { radius, borders } from '@/styles/Themes/Metrics';
import { Palette } from '@/styles/Themes/Palette';
import { Transition } from '@/styles/Themes/KeyFrames';

import Image from 'next/image';

export const CardWidth = styled.a`
    display: block;
    gap: 12px;
    width: 256px;

    ${Screen.ipad} {
        width: 249px;
        &:first-child:last-child {
            width: 249px;
        }
    }
`;

export const PreviewWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: ${cssVars.bg_white_dark3};
    border-radius: ${radius.small};
    box-shadow: ${cssVars.shadow_card_default};
    overflow-x: hidden;
    overflow-y: hidden;
    cursor: pointer;

    &:hover {
        box-shadow: ${cssVars.shadow_card_hover};
        ${Transition.normal};
    }
`;

export const ImageContainer = styled.div`
    position: relative;
    padding: 8px 8px 0;
    margin-bottom: 16px;
    height: 160px;
`;

export const ImgWrap = styled.div`
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    height: 100%;
`;

export const ImageComponent = styled(Image)`
    display: block;
    width: 100%;
    height: 100%;
    objectfit: cover;
`;

export const Title = styled.span`
    color: ${cssVars.fg_gray1_white};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    ${Text.Mobile.large.semibold}
    margin-bottom: 24px;
`;

export const PriceWrapper = styled.div`
    color: ${Palette.gray.gray1};
    background-color: ${Palette.white.normal};
    height: 28px;
    min-width: 52px;
    border-radius: ${radius.large};
    display: flex;
    position: absolute;
    top: 20px;
    left: 20px;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    padding: 4px 14px;
    border: ${borders.small} ${Palette.gray.gray4};

    ${Screen.ipad} {
        margin-right: 20px;
    }
    ${Screen.desktop} {
        height: 24px;
        ${Text.Desktop.large.semibold};
    }
`;

export const StrikeOut = styled.span`
    display: flex;
    align-items: center;
    text-decoration: line-through;
    margin-left: 4px;
    ${Text.Full.normal.regular};

    &::before {
        content: '$';
    }
`;

export const Price = styled.span`
    display: flex;
    align-items: center;
    ${Text.Mobile.medium.semibold};
    color: ${Palette.gray.gray1};

    &::before {
        content: '$';
    }
    ${Screen.ipad} {
        ${Text.Ipad.large.semibold};
    }
    ${Screen.desktop} {
        ${Text.Desktop.normal.semibold};
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    padding: 0 16px;
    flex: 1;
`;

export const DurationWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${cssVars.fg_gray2_gray3};
    ${Text.Full.large.regular}

    margin: 0 0 10px;
    ${Screen.ipad} {
        margin: 0 0 14px;
    }
    ${Screen.desktop} {
        ${Text.Full.medium.regular}
    }
`;

export const RatingText = styled.span`
    margin: 0 auto 0 6px;
`;

export const ClockIcon = styled(Tomorrow)`
    display: flex;
    align-self: center;
`;

export const Time = styled.div`
    text-align: end;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Reviews = styled.div`
    display: flex;
`;

export const Minutes = styled.div`
    display: flex;
    flex: 1 0 auto;
`;

export const NoReviews = styled.div`
    color: ${Palette.sand.darkSand};
`;

// export const NewToTripshock = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     position: absolute;
//     top: 25px;
//     right: -45px;
//     rotate: 45deg;
//     background-color: ${cssVars.bg_white_dark3};
//     width: 175px;
//     height: 34px;
//     color: ${Palette.green.normal};
//     ${Text.Full.small.semibold};
//     padding: 3px 65px;
//     text-align: center;
//     ${Screen.desktop} {
//         height: 40px;
//         width: 196px;
//         right: -55px;
//     }
// `;
