import { TNewAssets } from '@/screens/ActivityPage/service/createAssets';
import { ReactElement, useState, useRef, useEffect, useCallback } from 'react';
import * as S from './ImagesGallery.styles';
import { trackImageGallery } from '@/shared/Analytics/GA4/';
import { BookedCount } from '@/common/ui/styled/BookedCount/BookedCount';

type ImagesGalleryProps = {
    items: TNewAssets[];
    itemsElement: (page?: number) => JSX.Element[];
    additionalElement?: JSX.Element;
    withPaddings?: boolean;
    event?: boolean;
    totalPurchased?: string;
    recentPurchased?: string;
    startPosition?: number;
};

export type TMoveEvent =
    | React.MouseEvent<HTMLSpanElement, MouseEvent>
    | React.TouchEvent<HTMLDivElement>;

export const NewImagesGalleryComponent = ({
    itemsElement,
    items,
    additionalElement,
    withPaddings,
    event,
    totalPurchased,
    recentPurchased,
    startPosition,
}: ImagesGalleryProps): ReactElement => {
    const [page, setPage] = useState(startPosition || 1);
    const [isMoving, setIsMoving] = useState(false);
    const [posXstart, setPosXstart] = useState(0);
    const [posYstart, setPosYstart] = useState(0);
    const [transform, setTransform] = useState(`translateX(calc(-${page * 100}%))`);
    const [transition, setTransition] = useState('none');
    const slideRef = useRef<HTMLDivElement>(null);

    const moveSlides = useCallback((nextPage: number) => {
        setTransform(`translateX(calc(-${nextPage * 100}%))`);
    }, []);

    const calculateNewPage = useCallback(() => {
        setIsMoving(false);

        if (page === 0) {
            setTransition('none');
            const newPage = items.length - 2;

            setPage(newPage);
            moveSlides(newPage);
        }

        if (page === items.length - 1) {
            setTransition('none');

            const newPage = 1;

            setPage(newPage);
            moveSlides(newPage);
        }
    }, [items.length, moveSlides, page]);

    useEffect(() => {
        const currentSlideRef = slideRef.current;
        if (currentSlideRef) {
            currentSlideRef.addEventListener('transitionend', calculateNewPage);
        }

        return () => {
            currentSlideRef?.removeEventListener('transitionend', calculateNewPage);
        };
    }, [slideRef, calculateNewPage]);

    useEffect(() => {
        moveSlides(page);
    }, [moveSlides, page]);

    const onMove = (type: string, e: TMoveEvent, index?: number) => {
        e.stopPropagation();
        e.preventDefault();

        if (isMoving) {
            return;
        }

        setIsMoving(true);

        if (index !== undefined) {
            if (page === index) {
                return setIsMoving(false);
            }
            setPage(index);
        } else {
            setPage(type === 'right' ? page + 1 : page - 1);
        }

        setTransition('transform 450ms ease-in-out');

        if (event) {
            trackImageGallery();
        }
    };

    return (
        <S.Box withPaddings={withPaddings}>
            <S.ImageContainer>
                <S.GalleryWrapper
                    onTouchStart={(event) => {
                        if (items.length > 1) {
                            setPosXstart(event.changedTouches[0].clientX);
                            setPosYstart(event.changedTouches[0].clientY);
                        }
                    }}
                    onTouchEnd={(event) => {
                        if (items.length > 1) {
                            const changedTouches = event.changedTouches[0];

                            const posYRange = Math.abs(changedTouches.clientY - posYstart);
                            const posXRange = Math.abs(changedTouches.clientX - posXstart);

                            if (posYRange > posXRange) return;

                            if (posXRange > 30) {
                                if (changedTouches.clientX > posXstart) {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    onMove('left', event);
                                } else {
                                    onMove('right', event);
                                }
                            }
                        }
                    }}
                    transform={items.length > 1 ? transform : 'none !important'}
                    transition={transition}
                    ref={slideRef}
                    page={page}
                >
                    {itemsElement(page)}
                </S.GalleryWrapper>
                <BookedCount
                    totalPurchased={totalPurchased}
                    recentPurchased={recentPurchased}
                    fullWidth
                />
            </S.ImageContainer>
            {items.length > 1 && (
                <>
                    <S.LeftArrowBtn onClick={(e) => onMove('left', e)}>
                        <S.LeftArrowIcon />
                    </S.LeftArrowBtn>

                    <S.RightArrowBtn onClick={(e) => onMove('right', e)}>
                        <S.RightArrowIcon />
                    </S.RightArrowBtn>
                </>
            )}
            {additionalElement}
        </S.Box>
    );
};
