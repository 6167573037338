import styled from 'styled-components';
import Screen from '@/styles/Themes/Screen';
import { radius, borders } from '@/styles/Themes/Metrics';
import Palette from '@/styles/Themes/Palette';
import Text from '@/styles/Themes/Text';
import { cssVars } from '@/styles/Themes/cssVars';
import { HeartIcon } from '@/shared/images/icons/HeartIcon';
import { HeartIconFilled } from '@/shared/images/icons/HeartIconFilled';
import Image from 'next/image';

export const PriceWrapper = styled.div`
    background-color: ${Palette.white.normal};
    height: 34px;
    min-width: 60px;
    border-radius: ${radius.large};
    display: flex;
    align-items: center;
    position: absolute;
    top: 20px;
    left: 20px;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    border: ${borders.small} ${Palette.gray.gray4};
    padding: 0 15px;
    z-index: 5;

    ${Screen.ipad} {
        margin-right: 20px;
    }
    ${Screen.desktop} {
        min-width: 90px;
        height: 46px;
        ${Text.Desktop.large.semibold};
    }
`;

export const StrikeOut = styled.span`
    text-decoration: line-through;
    ${Text.Full.normal.regular};

    &::before {
        content: '$';
    }
`;

export const Price = styled.span`
    ${Text.Mobile.medium.semibold};
    color: ${Palette.gray.gray1};

    &::before {
        content: '$';
    }
    ${Screen.ipad} {
        ${Text.Ipad.large.semibold};
    }
    ${Screen.desktop} {
        ${Text.Desktop.large.semibold};
    }
`;

export const IconWrapper = styled.button`
    position: absolute;
    right: 20px;
    bottom: -21px;
    height: 43px;
    width: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    cursor: pointer;
    background-color: ${cssVars.bg_white_dark3};
    border: ${borders.small} ${cssVars.bg_ash_darker};
    border-radius: ${radius.large};
    box-shadow: 0px 4px 23px ${cssVars.shadow_normal};
    z-index: 5;

    ${Screen.desktop} {
        height: 50px;
        width: 50px;
        right: 15px;
        bottom: -25px;
    }

    &:hover {
        *:first-child {
            width: 24px;
            height: 23px;
        }
    }
`;

export const WishListBtn = styled(HeartIcon)`
    width: 19px;
    height: 18px;
    stroke: ${Palette.state.error};
`;

export const WishListBtnFilled = styled(HeartIconFilled)`
    width: 18px;
    height: 18px;
    stroke: ${Palette.state.error};
    *:first-child {
        fill: ${Palette.state.error};
    }
`;

export const Container = styled.div`
    position: relative;
    overflow: hidden;
    min-width: 100%;
    height: 100%;
    width: 100%;
    border-radius: 12px;
    & > span {
        height: 100% !important;
        width: 100% !important;
    }
`;

export const ImageTag = styled(Image)`
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    display: block;
    min-width: 100%;
    min-height: 100%;
`;

// export const ImgWrap = styled.div`
//     position: relative;
//     overflow: hidden;
//     height: 100%;
//     display: block;
//     width: 100%;
//     border-radius: ${radius.small};

//     height: 264px;

//     ${Screen.ipad} {
//         height: 332px;
//     }
//     ${Screen.desktop} {
//         height: 320px;
//     }
// `;
